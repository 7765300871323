import { render, staticRenderFns } from "./TheSpaceInvite.vue?vue&type=template&id=61081cc6&"
import script from "./TheSpaceInvite.vue?vue&type=script&lang=js&"
export * from "./TheSpaceInvite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VListItem,VListItemContent,VListItemGroup,VRadio,VRadioGroup,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61081cc6')) {
      api.createRecord('61081cc6', component.options)
    } else {
      api.reload('61081cc6', component.options)
    }
    module.hot.accept("./TheSpaceInvite.vue?vue&type=template&id=61081cc6&", function () {
      api.rerender('61081cc6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/space/components/TheSpaceInvite.vue"
export default component.exports
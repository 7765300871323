var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-center" },
      [
        _c(
          "v-list-item-group",
          {
            staticStyle: { width: "60%" },
            attrs: { disabled: "", color: "secondary" },
            model: {
              value: _vm.invitationType,
              callback: function($$v) {
                _vm.invitationType = $$v
              },
              expression: "invitationType"
            }
          },
          [
            _c(
              "v-row",
              {
                attrs: { justify: "center", align: "center", "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "4" } },
                  [
                    _c(
                      "v-list-item",
                      {
                        staticStyle: { height: "100px" },
                        on: { click: function() {} }
                      },
                      [
                        _c("v-list-item-content", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "overline mt-0 mb-1 d-flex justify-center"
                            },
                            [_vm._v("OPTION 1")]
                          ),
                          _c("div", { staticClass: "d-flex justify-center" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "subtitle-2 primary--text text-uppercase",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v(" user invitation ")]
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "6", lg: "4" } },
                  [
                    _c(
                      "v-list-item",
                      {
                        staticStyle: { height: "100px" },
                        on: { click: function() {} }
                      },
                      [
                        _c("v-list-item-content", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "overline mt-0 mb-1 d-flex justify-center"
                            },
                            [_vm._v("OPTION 2")]
                          ),
                          _c("div", { staticClass: "d-flex justify-center" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "subtitle-2 primary--text text-uppercase",
                                staticStyle: { "text-align": "center" }
                              },
                              [_vm._v("administrator invitation")]
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.invitationType !== undefined
      ? _c("div", [
          _vm.invitationType ===
            _vm.mainInvitationOptions.INSTANCE_USER_INVITATION &&
          _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
            ? _c(
                "div",
                {
                  staticClass: "mt-10 d-flex justify-space-around align-center"
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.instanceInvitationType,
                        callback: function($$v) {
                          _vm.instanceInvitationType = $$v
                        },
                        expression: "instanceInvitationType"
                      }
                    },
                    _vm._l(_vm.availableInstanceOptions, function(
                      instanceInvitationOption,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "d-flex justify-space-between"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center flex-column" },
                            [
                              _c("v-radio", {
                                staticClass: "mt-3 caption",
                                attrs: {
                                  color: "secondary",
                                  value: instanceInvitationOption.value,
                                  label: instanceInvitationOption.radioLabel
                                }
                              }),
                              instanceInvitationOption.radioSubHeader
                                ? _c("AddInstanceDialog", {
                                    attrs: {
                                      isTextActivator: true,
                                      activatorText:
                                        instanceInvitationOption.radioSubHeader
                                    },
                                    on: {
                                      inviteUsers: function($event) {
                                        return _vm.updateDataForTargetInstance({
                                          instanceName: $event.instanceName
                                        })
                                      },
                                      dialogIsOpen: function($event) {
                                        return _vm.moveToInviteToExistingInstanceTab(
                                          $event.value
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _vm.displayedComponent === "TheInstanceInvite"
                ? _c("TheInstanceInvite", {
                    tag: "component",
                    attrs: {
                      dataForInstanceToInviteTo: _vm.dataForInstanceInvitation
                    }
                  })
                : _c(_vm.displayedComponent, { tag: "component" })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }